import React from 'react';

import Layout from '../../components/Layout';

import pic1 from '../../assets/images/services/cloud-computing.png';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const CloudServices = () => (
  <Layout>
    <div id="main">
      <div className="inner box">
        <h1>Cloud Services</h1>
        <span className="image main">
          <img src={pic1} alt="" />
        </span>
        <article className="box">
        <p>
          A well-maintained cloud hosting service gives you remote access to your files while taking a large amount 
          of the memory burden off your in-house computers and devices. Everything you need is no longer stored 
          locally and is accessible anywhere with internet access.
          The benefits of cloud computing can be astounding for a company. As more and more employees work out of the 
          office on a regular basis or remotely on a permanent basis, having systems readily available from any location is a plus. 
          Besides systems being readily available from everywhere, there is a significant cost savings from reducing 
          the actual hardware needing to be maintained and replaced.
        </p>
        <Row><Col>
        <Card>
          <Card.Header>Software as a service (SaaS)</Card.Header>
            <Card.Body>
              <p>
              Cloud application services, or Software as a Service (SaaS), represent the largest cloud market and are 
              still growing quickly. SaaS uses the web to deliver applications that are managed by a third-party vendor 
              and whose interface is accessed on the clients’ side. Most SaaS applications can be run directly from a web 
              browser without any downloads or installations required, although some require plugins.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Infrastructure as a service (IaaS)</Card.Header>
            <Card.Body>
              <p>
              Cloud infrastructure services, known as Infrastructure as a Service (IaaS), are self-service models for accessing, 
              monitoring, and managing remote datacenter infrastructures, such as compute (virtualized or bare metal), storage, 
              networking, and networking services (e.g. firewalls). Instead of having to purchase hardware outright, 
              users can purchase IaaS based on consumption, similar to electricity or other utility billing.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Platform as a service (PaaS)</Card.Header>
            <Card.Body>
              <p>
              Cloud platform services, or Platform as a Service (PaaS), are used for applications, and other development, 
              while providing cloud components to software. What developers gain with PaaS is a framework they can build 
              upon to develop or customize applications. PaaS makes the development, testing, and deployment of applications 
              quick, simple, and cost-effective. With this technology, enterprise operations, or a third-party provider, 
              can manage OSes, virtualization, servers, storage, networking, and the PaaS software itself. Developers, however, manage the applications.
              </p>
            </Card.Body>
          </Card>
          </Col></Row>
        </article>
      </div>
    </div>
  </Layout>
);

export default CloudServices;